import { useState, useCallback } from 'react';
import { TrackerWrapper } from '../utils/tracker';
import { FeatureFlow, Location } from '../../../../types/api';

type UseDisclosureOptions = {
  onClose?: () => void;
  onOpen?: () => void;
  defaultIsOpen?: boolean;
};

export function useDisclosure(props: UseDisclosureOptions = {}) {
  const { onClose: onCloseProp, onOpen: onOpenProp, defaultIsOpen } = props;
  const [isOpen, setIsOpen] = useState(defaultIsOpen || false);

  const tracker = new TrackerWrapper();

  const onClose = useCallback(() => {
    setIsOpen(false);
    onCloseProp?.();
    tracker.trackUserInteraction({
      type: 'click',
      component: 'button',
      name: 'row-collapse',
      location: Location.APPLICATIONS_TABLE,
      featureFlow: FeatureFlow.NAVIGATION,
    });
  }, [onCloseProp]);

  const onOpen = useCallback(() => {
    setIsOpen(true);
    onOpenProp?.();
    tracker.trackUserInteraction({
      type: 'click',
      component: 'button',
      name: 'row-expand',
      location: Location.APPLICATIONS_TABLE,
      featureFlow: FeatureFlow.NAVIGATION,
    });
  }, [onOpenProp]);

  const onToggle = useCallback(() => {
    const action = isOpen ? onClose : onOpen;
    action();
  }, [isOpen, onOpen, onClose]);

  return {
    isOpen: !!isOpen,
    onOpen,
    onClose,
    onToggle,
  };
}
