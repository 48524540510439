import { useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useMatch } from 'react-router';
import toast from 'react-hot-toast';
import { TrackerWrapper } from '../../utils/tracker';
import {
  Avatar,
  Box,
  ButtonBase,
  Divider,
  Link,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  SvgIcon,
  Typography,
  useMediaQuery,
} from '@mui/material';
import useAuth from '../../hooks/useAuth';
import {
  KeyboardArrowDown,
  SettingsOutlined,
  Logout,
  TuneOutlined,
} from '@mui/icons-material';
import AttentionRequired from '../../../static/error/AttentionRequired.svg';
import {
  DEBUG_SETTINGS_PATH,
  USER_SETTINGS_PATH,
  INSTALLER_TYPES,
  Z_INDEXES,
} from '../../constants';
import { IS_DEV_OR_STAGING } from './userSettings/DebugForm';
import noticeError from '../../utils/errors/noticeError';
import { isConsultant, isInstaller } from '../../utils/helpers';
import { FeatureFlow } from '../../../../../types/api';

const tracker = new TrackerWrapper();

const menuItemStyles = { p: 1.5 };
const listIconStyles = {
  '&.MuiListItemIcon-root': {
    minWidth: 32,
    mr: 0,
    color: 'text.secondary',
  },
};

const AccountPopover = () => {
  const anchorRef = useRef(null);
  const { user, account, logout } = useAuth();
  const [open, setOpen] = useState(false);
  const fullName = `${user.first_name} ${user.last_name}`;
  const missingBankInfo = !(
    account.installer_bank_account_name &&
    account.installer_bank_account_number &&
    account.installer_bank_routing_number &&
    account.installer_bank_name &&
    account.location_of_bank
  );

  const isNotSettingsPage = !useMatch('/settings');
  const isInstallerAccount =
    isInstaller(user) &&
    (user.installer_role === INSTALLER_TYPES.ADMIN ||
      user.installer_role === INSTALLER_TYPES.ACCOUNTING);
  const isConsultantAccount = isConsultant(user);

  const showBankingInfo =
    isNotSettingsPage && (isInstallerAccount || isConsultantAccount);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    try {
      handleClose();
      await logout();
      tracker.trackUserInteraction({
        type: 'click',
        name: 'logout',
        component: 'navbar',
        location: Location.NAVBAR,
        featureFlow: FeatureFlow.LOGOUT,
      });
    } catch (err) {
      noticeError(err);
      toast.error('Unable to logout.');
    }
  };

  const cmq = useMediaQuery('(max-width:1100px)');

  return (
    <>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          ml: 0,
          position: 'relative',
          zIndex: Z_INDEXES.ACCOUNT_POPOVER,
        }}
      >
        {showBankingInfo && missingBankInfo && (
          <Box
            bgcolor="white"
            p={1.25}
            borderRadius={0.75}
            mb={2.5}
            sx={{
              mr: 'auto',
              height: '70px',
              width: '300px',
              display: 'inline-flex',
              alignItems: 'center',
              position: 'absolute',
              left: '-320px',
              top: '-15px',
              boxShadow: 'rgba(149, 157, 165, 0.4) 1px 1px 36px 6px;',
            }}
          >
            <SvgIcon component={AttentionRequired} sx={{ fontSize: 42 }} />
            <Typography sx={{ fontSize: '13px', justifyContent: 'left' }}>
              <b>Action Required:</b> Please add your bank information to{' '}
              <Link
                component={RouterLink}
                to={`/${USER_SETTINGS_PATH}/`}
                sx={{ textDecoration: 'none' }}
              >
                your account.
              </Link>
            </Typography>
            <Box
              sx={{
                height: '15px',
                width: '15px',
                background: 'white',
                transform: 'rotate(-45deg)',
                position: 'relative',
                right: 'calc(0vw - 12px)',
                top: 'calc(0vw - 0px)',
              }}
            />
          </Box>
        )}
        <Box
          component={ButtonBase}
          data-testid="profileTooltip"
          ref={anchorRef}
          onClick={handleOpen}
          spacing={1}
        >
          <Avatar
            src={user.avatar}
            alt={fullName}
            sx={{
              height: 42,
              width: 42,
            }}
          >
            {fullName[0]}
          </Avatar>
          <Typography
            variant="button"
            noWrap
            sx={{
              ml: 1.5,
              mr: {
                xs: 2,
                sm: 2,
                md: 2,
                lg: 3,
              },
              display: { lg: 'block', xs: cmq ? 'none' : 'block' },
            }}
          >
            Hi, {user.first_name}
          </Typography>
          <Box
            borderRadius="50%px"
            bgcolor={open ? '#F1F7FF' : undefined}
            sx={{ display: { lg: 'block', xs: cmq ? 'none' : 'block' } }}
          >
            <KeyboardArrowDown color="textPrimary" />
          </Box>
        </Box>
      </Box>
      <Menu
        anchorEl={anchorRef.current}
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'bottom',
        }}
        keepMounted
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: {
            minWidth: '140px',
            filter: 'drop-shadow(0px 7px 20px rgba(40, 47, 56, 0.1))',
            borderRadius: 0.75,
          },
          elevation: 0,
        }}
        MenuListProps={{
          sx: {
            py: 0,
          },
        }}
      >
        <MenuItem
          component={RouterLink}
          to={`/${USER_SETTINGS_PATH}/`}
          onClick={handleClose}
          sx={menuItemStyles}
        >
          <ListItemIcon sx={listIconStyles}>
            <SettingsOutlined fontSize="small" />
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography variant="body1" color="textSecondary">
                Settings
              </Typography>
            }
          />
        </MenuItem>
        {/* Enable feature flags for development and staging environment */}
        {IS_DEV_OR_STAGING && (
          <MenuItem
            component={RouterLink}
            to={`/${DEBUG_SETTINGS_PATH}/`}
            sx={menuItemStyles}
          >
            <ListItemIcon sx={listIconStyles}>
              <TuneOutlined fontSize="small" />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography variant="body1" color="textSecondary">
                  Debug
                </Typography>
              }
            />
          </MenuItem>
        )}
        <Divider
          flexItem
          variant="middle"
          // Need to use the & selector since base sx class isn't specific enough to override base styles
          sx={{ '&.MuiDivider-middle': { my: 0, mx: 1.5 } }}
        />
        <MenuItem onClick={handleLogout} sx={menuItemStyles}>
          <ListItemIcon sx={listIconStyles}>
            <Logout fontSize="small" />
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography
                color="textSecondary"
                variant="body1"
                textAlign={'start'}
              >
                Log out
              </Typography>
            }
          />
        </MenuItem>
      </Menu>
    </>
  );
};

export default AccountPopover;
