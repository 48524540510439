import {
  EventProperties,
  UserInteractionEventProperties,
  SuccessEventProperties,
  ErrorEventProperties,
} from '../../../../types/api';

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    _kmq: any[];
  }
}

export class Tracker {
  private static instance: Tracker;
  private constructor() {}

  /*    
    Event nomenclature goes a long way. 
    
    When adding new events, try to:

    - Use prefixes to groups related events. 
    - Understandable names. 
      app-table-tab-change vs 'nav-change'
    - Decouple from things that might change, CTA copy. 
      'application-begin' vs 'clicked-get-started' 
  */
  public static EVENTS = {
    // Tracking user interactions
    USER_INTERACTION: 'user-interaction',

    // Tracking success or error in requests
    SUCCESS: 'success',
    ERROR: 'error',
  };

  public static getInstance(): Tracker {
    if (!Tracker.instance) {
      Tracker.instance = new Tracker();
    }
    return Tracker.instance;
  }

  public track(event: string, properties?: EventProperties): void {
    const eventProperties = {
      ...properties,
      rosefieldVersion: __versionString__,
    };

    if (typeof window._kmq !== 'undefined') {
      window._kmq.push(['record', event, eventProperties]);
    } else {
      console.error('_kmq is not defined');
    }
  }

  public identify(email: string): void {
    if (typeof window._kmq !== 'undefined') {
      window._kmq.push(['identify', email]);
    } else {
      console.error('_kmq is not defined');
    }
  }
}

export class TrackerWrapper {
  private tracker: Tracker;

  constructor() {
    this.tracker = Tracker.getInstance();
  }

  public trackUserInteraction(params: UserInteractionEventProperties): void {
    this.tracker.track(Tracker.EVENTS.USER_INTERACTION, params);
  }

  // Track flow result (success or error)
  public trackFlowResult(
    params: SuccessEventProperties | ErrorEventProperties
  ): void {
    const isError = 'code' in params;
    const event = isError ? Tracker.EVENTS.ERROR : Tracker.EVENTS.SUCCESS;
    this.tracker.track(event, params);
  }

  public identify(email: string): void {
    this.tracker.identify(email);
  }
}
